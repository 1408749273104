import React from "react";
import Slider from "react-slick";

export default function SliderComponent({ children }) {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    fade: true,
    pauseOnHover: false,
    speed: 2000,
  };
  return <Slider {...settings}>{children}</Slider>;
}
